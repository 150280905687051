<template>
  <!--
    Displays the differences between an old and a new configuration file.
  -->
  <div class="auditLog">
    <LoadingPlaceholder v-if="loadingAudit" />

    <template v-else-if="auditLogs">
      <Portlet
        :title="$t('auditLogComponent.changes')"
        icon="history"
      >
        <div style="max-height: 350px; overflow-y: auto; width: 100%;">
          <table
            v-if="auditLogChanges != null"
            class="defaultTable"
          >
            <colgroup>
              <col
                class="hide-down-sm"
                width="130"
              >
              <col
                class="hide-down-sm"
                width="150"
              >
              <col width="150">
              <col>
            </colgroup>
            <thead>
              <tr class="border-bottom-0">
                <th class="hide-down-sm border-bottom">
                  {{ $t('createDate') }}
                </th>
                <th class="hide-down-sm border-bottom">
                  {{ $t('deviceDetailComponent.createdBy') }}
                </th>
                <th class="border-bottom">
                  {{ $t('field') }}
                </th>
                <th class="border-bottom">
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  {{ $t('changes') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(log, logIndex) in auditLogChanges"
                :key="`deviceDetailAuditLogTableRow-${ logIndex }`"
              >
                <td
                  v-tooltip="dateTime_fromNow(log.created_at)"
                  class="hide-down-sm"
                >
                  {{ dateTime_dateTimeSeconds(log.created_at) }}
                </td>
                <td class="hide-down-sm">
                  {{ log.created_by }}
                </td>
                <td
                  colspan="2"
                  class="p-0 m-0 border-right-0"
                >
                  <table class="defaultTable border-0">
                    <colgroup>
                      <col width="149">
                    </colgroup>
                    <tbody>
                      <tr
                        v-for="(change, changeIndex) in log.changes"
                        :key="`deviceDetailLogChangesTableRow-${ changeIndex }`"
                      >
                        <td>{{ change.field }}</td>
                        <td>
                          <AuditLogCompare
                            :old-val="change.old == null ? '' : change.old"
                            :new-val="change.new == null ? '' : change.new"
                            :is-configuration="isConfiguration(change)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else>
            {{ $t('noDataAvailable') }}
          </p>
        </div>
      </Portlet>
    </template>

    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'AuditLog',
  components: {
    AuditLogCompare: () => import('@/components/Device/DeviceDetail/AuditLogCompare.vue'),
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loadingAudit: true,
      auditLogs: null,
    }
  },
  computed: {
    auditLogCommands () {
      if (this.auditLogs == null) {
        return [];
      }
      if (this.auditLogs.length == 0) {
        return [];
      }
      let commands = [];
      this.auditLogs.forEach(auditLog => {
        if (auditLog.action === "command") {
          commands.push(auditLog);
        }
      });
      return commands.length > 0 ? commands : null;
    },
    auditLogChanges () {
      if (this.auditLogs == null) {
        return [];
      }
      if (this.auditLogs.length == 0) {
        return [];
      }
      let changes = [];
      this.auditLogs.forEach(auditLog => {
        if (auditLog.action === "change") {
          changes.push(auditLog);
        }
      });
      return changes.length > 0 ? changes : null;
    }
  },
  created () {
    this.getAuditLogs();
  },
  methods: {
    getAuditLogs () {
      this.loadingAudit = true;
      this.axios.get('/CentralDeviceManagement/GetAuditLog?deviceUuid=' + this.deviceId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.auditLogs = response.data;
        })
        .finally(() => {
          this.loadingAudit = false;
        });
    },
    isConfiguration (change) {
      return change.field == "configuration"
    }
  }
}
</script>

<style>
thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: -1px;
  background: #f5f5f5 !important;
}
pre {
  white-space: pre-line;
}
td {
  vertical-align: top;
}
</style>
